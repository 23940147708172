
import './App.css';
import { useState } from 'react';
import CookieModal from './Modals/CookieModal';
import Routes from './AllRoutes/Routes';
import { Toaster } from 'react-hot-toast';


function App() {
const [showCookieModal,setShowCookieModal] = useState(true)
  
  return (
    <>
     <Toaster />
      <CookieModal setShowCookieModal={setShowCookieModal} showCookieModal={showCookieModal} />
    <Routes/>
 
    </>
  );
}

export default App;
