import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import "./../Styles/aboutus.css";
function OurVision() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  
  return (
    <div id="OurVision">
      <Navbar />








      <div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-12 p-0">

            <h4 className="fw-bolder mt-4">Our Vision</h4>
           
            <p className="text-start py-2">
            PayZeus aims to revolutionize the digital banking landscape by providing innovative and reliable financial solutions. We believe in empowering our customers with the tools they need to manage their finances efficiently and securely. Our vision is to be the leading provider of virtual payment systems, enabling seamless transactions for individuals and businesses around the world.
            </p>

          </div>
        </div>
      </div>



      <Footer />

    </div>
  );
}

export default OurVision;
