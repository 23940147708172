import React from "react";
import { BsArrowRight } from "react-icons/bs";
import "../Styles/service.css";

function Services() {
  return (
    <>
      <div className="services">
        <div className="container">
          <h2>
          Our Key  <span>Products and Services</span>
          </h2>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="service-card">
                <img src="/images/wallet.png" alt="" />
                <h3>E-Wallet</h3>
                <p>
                You can store all your payment information securely in PayZeus e-Wallet, or digital wallet!  This allows you to make and receive payments from your mobile on-sight, instantly, securely, and free of charge!  You can also link your bank account(s) to your e-Wallet, however, all transactions will be converted into Zeus currency, at parity with your local currency, and free of charge!
                </p>
                {/* <button>
                  Get Started
                  <BsArrowRight />
                </button> */}
              </div>
            </div>

            <div className="col-md-4">
              <div className="service-card">
                <img src="/images/credit_card.png" alt="" />
                <h3>Complete Digital Banking Solution</h3>
                <p>PayZeus offers a complete digital Banking Solution not only for individuals from all walks of life, including the poor, the unbanked, the un-creditworthy, the unemployed, but also for the rich, the finance-savvy individuals with several formal bank accounts and with access to credit cards and loan facilities, as well as to the merchants who are keen to expand their customer base and reach as far and as wide as possible, and government agencies, public utilities and service providers who are keen to facilitate access for the disadvantaged segments of their customer base to free and efficient payment system, or even process the payrolls of their employees instantly, securely and free of charge.  In addition to the payment and transfer options, PayZeus offers a complete menu of fixed-deposits and saving accounts, investment/share purchase accounts, budget/spending monitoring and management, and micro credit facilities. </p>
                {/* <button>
                  Get Started
                  <BsArrowRight />
                </button> */}
              </div>
            </div>

            <div className="col-md-4">
              <div className="service-card">
                <img src="/images/storefront.png" alt="" />
                <h3>Credit Facilities</h3>
                <p>PayZeus offers various credit facilities for its qualified subscribers at competitive interest rates, including educational loans at concessional rates (rates below market price), emergency loans repayable over medium-term, and on Buy-Now-Pay-Later (BNPL) basis, requiring next-to-no documentation and payable directly to the beneficiaries! Eligible account-holders will be issued a “Digital Credit Card” for their online shopping from outlets honoring PayZeus!</p>
                {/* <button>
                  Get Started
                  <BsArrowRight />
                </button> */}
              </div>
            </div>

            <div className="col-md-4">
              <div className="service-card">
                <img src="/images/description.png" alt="" />
                <h3>Personal and Business Accounts</h3>
                <p>PayZeus offers both Personal and Business Account.  Each individual can open only one account per each registered mobile number, which can also be accessed and operated online through PayZeus official website for your country of residence.  Minors may also open an account if they have registered mobile number under their own name as allowed under the laws of the country.  Companies with valid trade/business license issued by the authorities, may open a Business Account on their registered Business Mobile Phone Number, to operate it as an official “business bank account”, where all revenues and outlays will be channeled into/from and proper record keeping shall be maintained by the PayZeus application for audit and tax collection by the authorities.</p>
                {/* <button>
                  Get Started
                  <BsArrowRight />
                </button> */}
              </div>
            </div>

            <div className="col-md-4">
              <div className="service-card">
                <img src="/images/devices.png" alt="" />
                <h3>Attractive “Rewards/Points/Offers” </h3>
                <p>
                PayZeus Account and Digital Credit Card Holders will be receiving attractive rewards/points for their purchases which can be encashed or used to make additional purchases.  A wealth of Special Merchant Offers for PayZeus customers will also be posted on both PayZeus websites and PayZeus Application, which can be redeemed for future purchases from those merchants. 
                </p>
                {/* <button>
                  Get Started
                  <BsArrowRight />
                </button> */}
              </div>
            </div>

            <div className="col-md-4">
              <div className="service-card">
                <img src="/images/arming_countdown.png" alt="" />
                <h3>Cross-Border Transactions</h3>
                <p>All transfers and payments in and out of your PayZeus individual accounts are free-of-change in the country where your mobile phone number is registered.  PayZeus will shortly offer, free-of-charge, cross-border, both inward and outward transfers and remittances for all PayZeus individual and business account holders.  Remittance charges constitute a large burden on both the sender and the receiver of funds in many countries and across various platforms, especially in countries where a large expatriate population resides.  PayZeus is determined to eliminate this burden for its subscribers.  Daily Exchange Rates for cross-border transfer and remittances are posted on both PayZeus website and Application. </p>
                {/* <button>
                  Get Started
                  <BsArrowRight />
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
