import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'

function EfficientEasyUseSteps() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='EfficientEasyUseSteps'>
    <Navbar />
   <div className="container py-5">

   <h4 className='fw-bolder mt-4' >It is Efficient and Easy to Use</h4>

   <ol className='open_individual_account_order_list' >
            <li className='p-2 m-0' >4 Easy Steps to Open Your Account</li>
            <li className='p-2 m-0' >3 Easy Steps to Recharge Your Account</li>
            <li className='p-2 m-0' >Easy to Understand Your Statement in One View</li>
            <li className='p-2 m-0' >Easy to Monitor Your Expenditures by Category, and by Vendor!</li>
            <li className='p-2 m-0' >Free-of-Charge to Open and Maintain Your Personal Account</li>
            <li className='p-2 m-0' >Real-Time Payments and Transfers</li>
            
         </ol>

   <p>At PayZeus, we make managing your finances simple and efficient. Our platform offers an intuitive experience with multiple features designed for your convenience.</p>
   

   <h5 className='fw-bolder mt-4' >3 Easy Steps to Recharge Your Account</h5>
   <ol>
                  <li>Log In: Access your PayZeus account through our website or mobile app.</li>
                  <li>Select Recharge Method: Choose your preferred recharge option.</li>
                  <li>Confirm and Recharge: Enter the details and confirm. Your account will be recharged instantly.</li>
                                   
                 </ol>


   <h6 className='fw-bolder mt-4' >Easy to Understand Your Statement in One View</h6>
<p>Our user-friendly dashboard allows you to see your account statement at a glance. Easily track your financial activities and stay informed.</p>

<h6 className='fw-bolder mt-4' >Easy to Monitor Your Expenditures by Category and Vendor</h6>
<p>Categorize your expenses and monitor them by vendor. This helps you keep track of where your money goes and manage your budget more effectively.</p>
      
<h6 className='fw-bolder mt-4' >Free-of-Charge to Open and Maintain Your Personal Account</h6>
<p>Opening and maintaining a PayZeus account is completely free. Enjoy all our services without any hidden fees.</p>
      
<h6 className='fw-bolder mt-4' >Real-Time Payments and Transfers</h6>
<p>All transactions, including payments and transfers, are processed in real-time. Experience seamless and instant financial operations with PayZeus.</p>

        </div>
   <Footer />
</div>
  )
}

export default EfficientEasyUseSteps;
