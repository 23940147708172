import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import "./../Styles/faq.css";
function Faq() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  
  return (
    <div id="Faq">
     
      <Navbar />








      <div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-12 p-0">

            <h4 className="fw-bolder mt-4 ">Frequently Asked Questions</h4>
           

           {<br/>}
          <h1 className="text-center">Coming soon...

            Text will be here
          </h1>
          </div>
        </div>
      </div>


      <Footer />

    </div>
  );
}

export default Faq;
