import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'

function AllTransactionsHome() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='AllTransactionsHome'>
    <Navbar />
   <div className="container py-5">

   <h4 className='fw-bolder mt-4' >All Transactions in Real Time!</h4>
   <p>All Payments, in-ward Transfers, Deposits, Balance Enquiries, and Expenditure Reporting are in real time!
At PayZeus, we ensure that your financial activities are as efficient and up-to-date as possible. With our cutting-edge technology, all transactions, including payments, inward transfers, deposits, balance enquiries, and expenditure reporting, are processed in real-time.
</p>

   <h5 className='fw-bolder mt-4' >Why Real-Time Transactions Matter</h5>
   <h6 className='fw-bolder mt-4' >Immediate Payments</h6>
   <p>Make payments that reflect instantly. Whether you're paying bills, sending money to friends, or making purchases, you can trust that your payments are processed immediately.</p>
   <h6 className='fw-bolder mt-4' >Instant Inward Transfers</h6>
   <p>Receive funds without delay. Inward transfers to your PayZeus account appear in real-time, ensuring that you have access to your money when you need it most.</p>
   <h6 className='fw-bolder mt-4' >Quick Deposits</h6>
   <p>Deposit funds into your PayZeus account seamlessly. Our real-time processing means your deposits are available right away, giving you complete control over your finances.</p>
   <h6 className='fw-bolder mt-4' >Up-to-the-Minute Balance Enquiries</h6>
   <p>Stay informed with instant balance updates. Check your account balance at any time, and get accurate, real-time information on your available funds.</p>

   <h6 className='fw-bolder mt-4' >Real-Time Expenditure Reporting</h6>
   <p>Track your spending with real-time expenditure reports. Monitor your transactions as they happen, and keep your finances organized and transparent.</p>
   <h5 className='fw-bolder mt-4' >Experience the PayZeus Advantage</h5>
<p>With PayZeus, you never have to wait for transaction updates. Our commitment to real-time processing ensures that your financial information is always current, helping you make informed decisions on the spot.</p>
<h5 className='fw-bolder mt-4' >Join PayZeus Today</h5>
<p>Experience the convenience and efficiency of real-time transactions. Sign up for PayZeus and manage your finances with speed and accuracy.</p>
        <a href='#'>Sign Up Now</a>
        </div>
   <Footer />
</div>
  )
}

export default AllTransactionsHome;
