export const CountryCode = [
    {
    "name": "English",
    "code": "GB"
    },
    {
    "name": "Arabic",
    "code": "SA"
    },
    {
     "name": "Bahasa",
    "code": "ID"
    },
    {
        "name": "Bengali",
       "code": "IN"
       },
       {
        "name": "Mandarin Chinese",
       "code": "CN"
       },
       {
        "name": "French",
       "code": "FR"
       },
       {
        "name": "German",
       "code": "DE"
       },
       {
        "name": "Hausa",
       "code": "NG"
       },
       {
        "name": "Hindi",
       "code": "IN"
       },
       {
        "name": "Japanese",
       "code": "JP"
       },
       {
        "name": "Kazakh",
       "code": "KZ"
       },
       {
        "name": "Korean",
       "code": "KP"
       },
       {
        "name": "Marathi",
       "code": "IN"
       },
       {
        "name": "Panjabi",
       "code": "IN"
       },
       {
        "name": "Portuguese",
       "code": "RU"
       },

       {
        "name": "Spanish",
       "code": "ES"
       },
       {
        "name": "Swahili",
       "code": "TZ"
       },
       {
        "name": "Tajik",
       "code": "TJ"
       },
       {
        "name": "Tamil",
       "code": "IN"
       },
       {
        "name": "Tagalog",
       "code": "PH"
       },
       {
        "name": "Telugu",
       "code": "IN"
       },
       {
        "name": "Turkish",
       "code": "TR"
       },
       {
        "name": "Urdu",
       "code": "PK"
       },
       {
        "name": "Vietnamese",
       "code": "VN"
       },



    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
]