import axios from 'axios'
import { SuccessToaster, ErrorToaster } from '../Toasters';
import { baseURL } from '../baseUrl';

export const InquiryApi=(formdata, setFirstName, setEmail, setMessage, setMobile, setSubject)=>{
    let data = JSON.stringify(formdata);
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}contact-request`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        if(response.data.status==='failed'){
          ErrorToaster(response.data.message)
        } else{
            SuccessToaster(response.data.message)
            setFirstName('')
            setMessage('')
            setEmail('')
            setSubject('')
            setMobile('')
        }
      })
      .catch((error) => {
        console.log(error);
      });
}

export const SubscriptApi=(email, setEmail)=>{
    let data = JSON.stringify({
        "email": email
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}subscriber`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        if(response.data.status==='failed'){
            ErrorToaster(response.data.message)
          } else{
              SuccessToaster(response.data.message)
              setEmail('')
          }
      })
      .catch((error) => {
        console.log(error);
      });
}