import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'
const color ={
color:"#000080", 
textDecoration:"none",

};

const linksignup ={
  color:"#000080", 
  textDecoration:"none",
  fontSize:"24px",
  
  };
function RegisteredMobileNumber() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='RegisteredMobileNumber'>
    <Navbar />
   <div className="container py-5">

   <h4 className='fw-bolder mt-4' >Your Registered Mobile Number is Your Unique PayZeus Bank Account Number - Worldwide!</h4>
   <h5 className='fw-bolder mt-4' >Instant Access to Credit</h5>
   
<p>Your registered mobile number, either on prepaid or post-paid plans, excluding the prefix 00, or + sign, and including the country code, the mobile carrier code, without the prefix “0”, in your country, and your full 7 or 8 digits mobile number, will be your unique bank account number. All incoming transfers and outgoing payments and all your unique PayZeus banking services will be identified with this account number!</p>

<p>At PayZeus, we revolutionize banking by using your registered mobile number as your unique bank account number. Whether you're on a prepaid or post-paid plan, your mobile number serves as your PayZeus identifier, making banking simpler and more accessible.
Experience the future of banking with PayZeus. Your registered mobile number is all you need to manage your finances efficiently and securely.
</p>
<h5 className='fw-bolder mt-4' >How It Works</h5>

<ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Global Identification: Your mobile number, including the country code and mobile carrier code (without the prefix “0”), forms your unique PayZeus account number.</li>
            <li className='p-2 m-0' >Seamless Transactions: Use your mobile number for all incoming transfers and outgoing payments. This ensures that every transaction is quick, secure, and easily identifiable</li>
            
          
         </ul>
         <h5 className='fw-bolder mt-4' >Sign Up Now</h5>
         
<p>Download PayZeus Application from your favorite Appstore, or PayZeus official website, and sign up today to simplify your banking experience!</p>
<a href='#' style={linksignup}>Sign Up</a>
<h5 className='fw-bolder mt-4' ><a href="#" style={color}>Contact Us</a></h5>
        </div>
   <Footer />
</div>
  )
}

export default RegisteredMobileNumber;
