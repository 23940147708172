import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import "./../Styles/aboutus.css";
function VisionAndMission() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id="aboutus">
      <Navbar />

      <div className="container-fluid mb-4 p-0">
        <div className="row">
          <div className="col-lg-12 aboutus_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 aboutus_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">OUR VALUES</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-lg-12 p-0">
            <p className="text-start">
              In God We Trust, the earth is our refuge, the world is our client,
              and the people are our strength.
            </p>
            <p className="text-start">
              No discrimination at any level or on any basis, and we count
              everyone.
            </p>
            <p className="text-start">
              An honest and dedicated service to every customer, around the
              clock, every day.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-4 p-0">
        <div className="row">
          <div className="col-lg-12 aboutus_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 aboutus_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">OUR VISION</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-lg-12 p-0">
            <p className="text-start">
            For the PayZeus digital Banking and Payment System to become the number one choice (i.e., attaining a 70% market share) by the unbanked population in the developing countries and emerging economies worldwide by December 2026. 
            </p>
          </div>
        </div>
      </div>


      <div className="container-fluid mb-4 p-0">
        <div className="row">
          <div className="col-lg-12 aboutus_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 aboutus_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">OUR MISSION</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-lg-12 p-0">
            <p className="text-start">
            To make the online banking, payment and shopping accessible to all those who cannot have a bank account or a credit card with a formal banking and financial institution for any reason.   We aim to promote financial inclusion, improve quality of life, contribute to saving the environment, and enhance economic prosperity and thereby alleviate poverty around the world.
            </p>
          </div>
        </div>
      </div>




      <Footer />
    </div>
  );
}

export default VisionAndMission;
