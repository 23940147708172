import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'
import './../Styles/openindividual.css'

function OpenIndividualAccount() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div>
    <Navbar />
   <div className="container py-5">

   <h4 className='fw-bolder mt-4' >To Open an Individual Account</h4>

   <ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Select your Country (dropdown list of the countries where PayZeus is operational)</li>
            <li className='p-2 m-0' >Select your preferred language (dropdown of the languages offered in various countries, starting with English, Hindi, Urdu, Spanish, Chinese, Arabic, etc.)</li>
          
         </ul>




       <h5 className='fw-bolder mt-4' >How to Open an Individual Account</h5>
         <ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Download PayZeus Application</li>
            <li className='p-2 m-0' >QR to Download the Application</li>
            <li className='p-2 m-0' >Enter your Registered Mobile Number (this will be your unique bank account number worldwide)</li>
            <li className='p-2 m-0' >Enter your First and Last (Family) Name as per the registered mobile number records</li>
            <li className='p-2 m-0' >Enter the OTP Number you will receive on your mobile (this OTP is valid for 10 minutes)</li>
            <li className='p-2 m-0' >Record Your Voice when prompted to secure your access (Follow the steps)</li>
            <li className='p-2 m-0' >Track your Expenses and Suppliers <span>Read More</span></li>
            <li className='p-2 m-0' >Earn Merchants/Suppliers Offers/Rewards (this page would be different for various countries and localities and will be uploaded and updated by PayZeus Team)</li>
            <li className='p-2 m-0' >Available Rewards/Offers (link it to the Business Account Page) </li>
         </ul>
        </div>
   <Footer />
</div>
  )
}

export default OpenIndividualAccount
