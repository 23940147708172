import React from "react";
import {BsArrowDown} from 'react-icons/bs'
import "../Styles/registerpayzeus.css";
import {
  
  Link,
} from "react-router-dom";


function RegisterPayzeus() {
  return (
    <div className="registerpayzeus">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div className="registerpayzeus-main  w-sm-0">
              <h4>How To Register</h4>
              <h2 className="mb-3">
              Follow 4 Quick, Easy Steps
To Open Your PayZeus Account

              </h2>
              <p>Your Registered Mobile Number, including the Country Code, like 0091, the Carrier Code, like 55 without the zero in front, and your 7 or 8 digits mobile number, like 1236578) will be Your Global PayZeus Bank Account Number to Receive-in and Transfer-out funds! </p>

              <div class="order-track">
                <div class="order-track-step">
                  <div class="order-track-status">
                    <span class="order-track-status-dot"><BsArrowDown/></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <span class="order-track-text-sub">Download the Application</span>
                  </div>
                </div>
                <div class="order-track-step">
                  <div class="order-track-status">
                    <span class="order-track-status-dot"><BsArrowDown/></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <span class="order-track-text-sub">
                    Open Account Using Your Registered Mobile Number
                    </span>
                  </div>
                </div>
                <div class="order-track-step">
                  <div class="order-track-status">
                    <span class="order-track-status-dot"><BsArrowDown/></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <span class="order-track-text-sub">Create Your Profile and Register Your Voice for Secure Access</span>
                  </div>
                </div>
                <div class="order-track-step">
                  <div class="order-track-status">
                    <span class="order-track-status-dot"><BsArrowDown/></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <span class="order-track-text-sub">Verify the OTP Sent to Your Mobile to Complete the Process</span>
                  </div>
                </div>
              </div>

              <div className="my-4 d-flex  ">
                <img src="/images/google-play-button.svg" alt="img" /> 
                <img src="/images/apple-store-button.svg" alt="img" />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center ">
            <div className="registerpayzeus-image">
              {/* <img src="/images/google.png" alt="img" />
            <img src="/images/apple.png" alt="img" /> */}
              <img src="/images/mobilegif2.gif" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPayzeus;
