import React, { useState } from "react";
import { BsTrash3 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "../Styles/sidenavbar.css";

const SideNavBar = ({ openSidenavbar, setOpenSidenavbar }) => {

    const data=[1,2,3,4,5,6,7]

    const navigation =useNavigate()
  //   const [openPanel, setOpenPanel] = useState(false);
  return (
    <div>
      <div
        style={{ width: openSidenavbar ? "300px" : "0px" }}
        id="mySidenav"
        className="sidenav"
      >
       <div className="d-flex justify-content-around align-items-center">
       <h3 className="sidenav-heading p-0 m-0">Payzeus</h3>
        <a
          className="closebtn"
          onClick={() => {
            setOpenSidenavbar(false);
          }}
        >
          &times;
        </a>
       </div>
       
        <div className="cart-slider">
            <ul className="p-0 m-0">
                  <li>Home</li>
                  <div class="service_dropdown">
                  <li>Services & Product</li>
                    <div class="service_dropdown_content">
                      <a to="/">Digital Banking</a>
                      <a to="/">Bill Payments/Transfers</a>
                      <a to="/">Fixed Deposits</a>
                      <a to="/">Savings Accounts</a>
                      <a to="/">ZeusBank Shareholding Account</a>
                      <a to="/">Digital Debit Cards</a>
                      <a to="/">Digital Credit Cards</a>
                      <a to="/">Emergency Lending Facilities</a>
                      <a to="/">University Tuition Loans </a>
                      <a to="/">Rewards on Your PayZeus Purchases </a>
                    </div>
                  </div>

                  <div class="open_account_dropdown">
                    <li>Open Account</li>
                    <div class="open_account_dropdown_content">
                      <a onClick={()=>navigation("/open-individual-account")}>Individual</a>
                      <a onClick={()=>navigation("/open-business-account")}>Business</a>


                      
                    </div>
                  </div>



                    <div class="service_dropdown">
                  <li>About Us</li>
                    <div class="service_dropdown_content">
                    <a  onClick={()=>navigation("/about-us")}>About PayZeus</a>
                      <a  onClick={()=>navigation("/our-values")}>Our Values</a>
                      <a onClick={()=>navigation("/our-vision")}>Our Vision</a>
                      <a onClick={()=>navigation("/our-mission")}>Our Mission</a>
                      <a onClick={()=>navigation("/faq")}>FAQs</a>
                      <a onClick={()=>navigation("/news")}>News</a>
                     
                      <a onClick={()=>navigation("/feedback")}>Provide Feedback & Ask</a>

                    
                    </div>
                  </div>


                 

                 
                  <li onClick={()=>navigation('/visit')} >Visit ZeusBank</li>

             

                  <div class="service_dropdown">
                  <li  onClick={()=>navigation('/recharge')} >Recharge</li>
                    <div class="service_dropdown_content">



                    <a to="/">Using Credit Cards</a>
                         <a to="/">Using Your Formal Bank Account </a>
                          <a to="/">Scanning QR</a>

                      <div class="navbar_dropdown_cash">
                          <a to="/">Using Cash</a>
                        <div class="navbar_dropdown-content_cash">
                          <a to="/">Authorized Stores</a>
                          <a to="/">Authorized Street Vendors </a>
                          </div>
                          </div>

                    </div>
                  </div>
                 
            </ul>
        </div>

   
      </div>
    </div>
  );
};

export default SideNavBar;
