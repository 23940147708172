import React from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import "../Styles/voucher.css";

function Voucher() {
  return (
    <div className="voucher">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="voucher-image">
              <img src="/images/voucher.jpg" alt="" />
            </div>
          </div>

          <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-start mt-sm-3 mt-md-0 mt-0">
            <div className="voucher-main w-sm-75 w-100 w-sm-0">
              <h2 className="mb-3">PayZeus No Recharge Fee</h2>
              <p>
              Use PayZeus Prepaid Vouchers, available both online and at Registered Outlets and Street Vendors in your city, town, and village, to recharge and top-up your account!
              </p>

              {/* <div className="my-4">
                <button className="voucher-learn-btn">
                  <BiRightArrowCircle />
                  Learn More
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Voucher;
