import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import '../Styles/rechargepage.css'


function RechargePage() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar />




      <div className="container pt-5">
      
      <div className="row">
        <div className="col-md-8">
        <h4 className="fw-bolder mt-4">Recharge </h4>
        <ul className="open_business_account_order_list">
          <li className="p-2 m-0">
            from Participating Retail Networks (dropdown of list of
            Participating Retail Networks in each country where PayZeus is
            offered){" "}
          </li>
          <li className="p-2 m-0">
            from Moms & Pops Shops that Display this Sign: PayZeus Is Accepted
            Here
          </li>
          <li className="p-2 m-0">
            from Street Vendors at every corner of your town and village (maybe
            showing a young boy/man’s picture selling PayZeus cards in a rural
            area){" "}
          </li>
          <li className="p-2 m-0">from your other online banking accounts </li>
        </ul>
        </div>

        <div className="col-md-4">
          <div className="rechargeimg">
          <img className="img-fluid" src="/images/voucher.jpg" alt="" />
          </div>
        </div>
      </div>
      
      
      </div>






      <div className="container pb-5">
        
        <h4 className="fw-bolder mt-4">
          PayZeus offers a full menu of digital banking, and payment and savings
          options:
        </h4>
        <ul>
          <li className="p-2 m-0">e-wallet</li>
          <li className="p-2 m-0">transfers and payments</li>
          <li className="p-2 m-0">savings and credit facilities</li>
          <li className="p-2 m-0">time deposits and savings accounts</li>
          <li className="p-2 m-0">digital debit cards and credit cards</li>
          <li className="p-2 m-0">
            Automated Teller Machines (ATMs) in select cities and markets, and
            in the near future, cross-border transfers and online shopping
            facilities
          </li>
        </ul>

        <h4 className="fw-bolder mt-4">PayZeus will be widely accepted by:</h4>
        <ul>
          <li className="p-2 m-0">Merchants and retail outlets</li>
          <li className="p-2 m-0">
            Utility and communication service providers
          </li>
          <li className="p-2 m-0">government bodies</li>
          <li className="p-2 m-0">
            PayZeus will be licensed by the respective monetary authorities in
            each country.
          </li>
          <li className="p-2 m-0">
            The first 10 million PayZeus account holders will be allowed to
            purchase shares in the ZeusBank, (www.ZeusBank.net), subject to
            approval by the monetary authorities in each country. ZeusBank
            underwrites all financial assets maintained on the PayZeus network,
            and will be regulated and supervised by the monetary authorities in
            each country where PayZeus is offered.{" "}
          </li>
          <li>
            All your deposits and financial assets maintained on PayZeus network
            will be fully insured by international insurance companies.{" "}
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default RechargePage;
