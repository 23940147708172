import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'
import {
  
  Link,
} from "react-router-dom";
function RequiresNoFormalBankAccount() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='RequiresNoFormalBankAccount'>
    <Navbar />
   <div className="container py-5">

   <h4 className='fw-bolder mt-4' >Requires No Formal BankA ccount</h4>
   <p>You don’t need to have an account at a commercial bank to open a PayZeus Account, but you can link all your other account(s) to your PayZeus Account!</p>

   <h5 className='fw-bolder mt-4' >No Formal Bank Account Required</h5>
   <p>At PayZeus, financial management is accessible to everyone. No need for a traditional bank account to open a PayZeus account. Link all your existing accounts for a unified financial experience.
PayZeus is an advanced digital wallet and financial management platform, designed to handle your financial activities efficiently and securely from one place.
</p>
<h5 className='fw-bolder mt-4' >Why Choose PayZeus?</h5>
<h6 className='fw-bolder mt-4' >No Need for a Commercial Bank Account</h6>
<ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Inclusivity: Open an account without a commercial bank account.</li>
            <li className='p-2 m-0' >Flexibility: Perfect for those who find traditional banking restrictive.</li>
                      
         </ul>
         <h6 className='fw-bolder mt-4' >Link Multiple Accounts</h6>
<ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Unified Management: Integrate all your financial accounts into PayZeus.</li>
            <li className='p-2 m-0' >Enhanced Oversight: Simplify the management and monitoring of your finances.</li>
                      
         </ul>

         <h6 className='fw-bolder mt-4' >Secure and Convenient Transactions</h6>
<ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Robust Security: Advanced encryption to protect your data.</li>
            <li className='p-2 m-0' >24/7 Convenience: Conduct transactions anytime, anywhere using your mobile or desktop.</li>
                      
         </ul>

         <h6 className='fw-bolder mt-4' >User-Friendly Interface</h6>
<ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Intuitive Design: Easy for all users to navigate.</li>
            <li className='p-2 m-0' >Streamlined Management: Simplify your financial activities.</li>
                      
         </ul>

<h6 className='fw-bolder mt-4' >Comprehensive Financial Management Tools</h6>
<ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Expense Tracking: Monitor and manage your expenses efficiently.</li>
            <li className='p-2 m-0' >Financial Analytics: Gain insights and set financial goals.</li>
                      
         </ul>
         </div> 
   <Footer />
</div>
  )
}

export default RequiresNoFormalBankAccount;
