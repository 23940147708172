import React,{useState} from "react";
import "../Styles/languagesection.css";

function LangaugaeSection() {
  const [selectedCountry, setSelectedCountry] = useState('US')
  return (
   <>
   <div className="container language_header">
     <ul className="m-0 p-0 d-flex flex-wrap justify-content-around align-items-center">
        <li className={selectedCountry==='US'? 'active': ''} onClick={()=>setSelectedCountry('US')} >USA</li>
        <li className={selectedCountry==='IN'? 'active': ''} onClick={()=>setSelectedCountry('IN')} >INDIA</li>
        <li className={selectedCountry==='SL'? 'active': ''} onClick={()=>setSelectedCountry('SL')} >SIERRA LEONE</li>
        <li className={selectedCountry==='EL'? 'active': ''} onClick={()=>setSelectedCountry('EL')} >EI SALVADOR</li>
        <li className={selectedCountry==='CN'? 'active': ''} onClick={()=>setSelectedCountry('CN')} >CHINA</li>
     </ul>
   </div>
    <div className="languagesection">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div className="languagesection-main w-sm-0">
              <span>Get PayZeus now</span>
              <h2 className="mb-3">
                PayZeus Application, made available in each country, runs in both English and
               official Local Language(s)!
              </h2>

              <div className="my-4">
                <button className="languagesection-learn-btn">
                  Learn More
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="languagesection-image">
            
              
              <img
                              width={20}
                              height={20}
                              className="mx-1"
                              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry}.svg`}
                              alt="img"
                            />

            </div>
          </div>
        </div>
      </div>
    </div>
   </>
  );
}

export default LangaugaeSection;
