import React from 'react'
import Navbar from '../sections/Navbar'
import Banner from '../sections/Banner'
import BannerSecond from '../sections/BannerSecond'
import BankingSolution from '../sections/BankingSolution'
import SafeAndSecure from '../sections/SafeAndSecure'
import RegisterPayzeus from '../sections/RegisterPayzeus'
import Maintain from '../sections/Maintain'
import Services from '../sections/Services'
import Deposite from '../sections/Deposite'
import Voucher from '../sections/Voucher'
import Banking from '../sections/Banking'
import Sector from '../sections/Sector'
import LangaugaeSection from '../sections/LangaugaeSection'
import SpecialOffer from '../sections/SpecialOffer'
import Footer from '../sections/Footer'
function Home() {
  return (
    <>
      <Navbar/>
      <Banner/>
      <BannerSecond/>
      <BankingSolution/>
      <SafeAndSecure/>
      <RegisterPayzeus/>
      <Maintain />
      <Deposite/>
      <Services/>
      <Voucher />
      <Banking />
      <LangaugaeSection /> 
      <Sector/>
    
      <SpecialOffer/>
      <Footer/>
    </>
  )
}

export default Home
