import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

function VisitPayzeus() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
        <Navbar />
       <div className="container py-5">
           <h4 className='fw-bolder mt-4' >Visit ZeusBank</h4>
            <p className='py-1' >PayZeus runs on an alternative currency called Zeus.  Zeus is not a cryptocurrency; it is rather a digital currency, whose value set at parity with the local currency of the country/state/territory/jurisdiction where Zeus will be made available on PayZeus platform.  It is, therefore, a digital currency that is freely convertible with all legal tenders of the world, at the rates announced daily by the ZeusBank on PayZeus platform on web and your mobile application!</p>

            <p className='' >ZeusBank shall be a licensed bank by the monetary authorities of the US, and its assets, including all PayZeus account holders’ deposits shall be federally insured.  ZeusBank shall have no branches in the US, however, will establish branch offices in the countries where PayZeus shall be offered within the country’s legal and regulatory framework.  Additional information shall be provided in the ZeusBank.net (under construction).   </p>

            
       </div>
       <Footer />
    </div>
  )
}

export default VisitPayzeus
