import React from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import "./../Styles/banking.css";

function Banking() {
  return (
    <>
      <div className="banking">
        <div className="container">
          <h4 className="fw-bold">
          Choose Capital Account Option   {'>>>'} Buy Shares in ZeusBank
          </h4>
          <p>The First 10 Million PayZeus Subscribers/Account-holders will have an opportunity to Become Shareholders of the ZeusBank, the bank behind PayZeus, up to a maximum of US$25 per subscriber, or up to the maximum amount allowed under the rules and regulations of the central bank and the stock market of your country, if that is lower than $25 per individual, by allocating a portion of their funds into the Capital Account option provided in the Application.</p>
          <div className="row">
            <div className="col-md-6">
              <div className="banking-img">
                <img src="/images/banking.png" alt="image" />
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center align-items-start ">
              <div className="banking-left">
                <h3>Manage Your </h3>
                <h3>Budget Efficiently</h3>
                <h5>
                  Monitor Expenses Against Budgeted Amounts, Generate Expense
                  Reports by Category, Vendor/Payee, and Historically
                </h5>
                <button>
                  <BiRightArrowCircle />
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="banking-bottom py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banking-img">
                <img src="/images/atm.png" alt="image" />
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center align-items-center ">
              <div className="banking-left">
                
                <h3>
                Zeus is an Alternative Currency to Your Local Currency! 
						Both are Perfectly Inter-Changeable, at Parity 1:1, at
						PayZeus ATMs, and at Select Commercial Banks!

                </h3>
                <h1>(ATM Fees may apply!)  </h1>

                <p>PayZeus will establish an ATM network 
						in select cities and locations.  Small withdrawals in Zeus
Currency could be converted into local currency at the
rate of one-to-one and funds made available in local
currency to you (small ATM Fee will apply)!  
PayZeus will also open Accounts at select commercial
Banks, where larger amounts by Business Account Holders
could be exchanged for Local Currency, at a small discount!   
</p>
                {/* <button>
                  <BiRightArrowCircle />
                  Learn More
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banking;
