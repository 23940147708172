import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'
import './../Styles/openindividual.css'

function FixedDeposits() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='FixedDeposits'>
    <Navbar />
   <div className="container py-5">

   <h4 className='fw-bolder mt-4' >Fixed Deposits</h4>
   <h6 className='fw-bolder mt-4' >Secure Your Future with PayZeus Fixed Deposits</h6>
   <p>At PayZeus, we understand the importance of securing your financial future. Our fixed deposits offer a reliable and profitable way to grow your savings. As part of our all-in-one banking system, PayZeus provides a seamless and secure experience, ensuring that your investments are both safe and rewarding.</p>
        
   <h4 className='fw-bolder mt-4' >Fixed Deposits?</h4>
        <p>Fixed deposits are a type of investment where you deposit a lump sum of money for a fixed period at a predetermined interest rate. At PayZeus, we offer competitive interest rates that maximize your returns, making fixed deposits an attractive option for anyone looking to grow their savings.</p>
        <h4 className='fw-bolder mt-4' >Benefits of PayZeus Fixed Deposits</h4>
        
        <ul className='open_individual_account_order_list' >
          <li className='p-2 m-0' ><strong>Guaranteed Returns:</strong> With fixed deposits, you earn a guaranteed interest rate, providing a secure investment with predictable returns.</li>
           
          <li className='p-2 m-0' ><strong>Flexible Tenure Options: </strong> Choose from a variety of tenure options to suit your financial goals, ranging from short-term to long-term deposits.</li>
          <li className='p-2 m-0' ><strong>High-Interest Rates: </strong> Benefit from our competitive interest rates, ensuring that your money works harder for you.</li>
          <li className='p-2 m-0' ><strong>Safety and Security: </strong>Your investments are safe with PayZeus, thanks to our robust security measures and reliable banking practices.</li>
         </ul>
         <h4 className='fw-bolder mt-4' >Part of Our All-In-One Banking System</h4>
         <p>PayZeus offers an all-in-one banking system that integrates various financial services, including fixed deposits, digital banking, and alternative currency options. This comprehensive approach ensures that you have all the tools you need to manage your finances effectively.</p>

         <h5 className='fw-bolder mt-4' >Digital Banking Convenience</h5>
         <p>
Our digital banking platform makes managing your fixed deposits easy and convenient. With online access to your accounts, you can monitor your investments, view interest accruals, and manage your deposits anytime, anywhere.
</p>
<h5 className='fw-bolder mt-4' >Diverse Investment Options</h5>
<p>
In addition to fixed deposits, PayZeus provides a range of investment options, including alternative currency investments. This diversity allows you to create a balanced and robust investment portfolio.
</p>
<h5 className='fw-bolder mt-4' >Alternative Currency Investments</h5>
<p>
For those looking to diversify their investments, PayZeus offers options in alternative currencies like Bitcoin and Ethereum. These investments can provide higher returns and add a modern twist to your investment strategy.
</p>
<h4 className='fw-bolder mt-4' >Commercial Bank Reliability</h4>
<p>As a leading digital bank, PayZeus combines the innovation of digital banking with the reliability of a commercial bank. Our commitment to excellence and customer satisfaction ensures that your financial needs are met with the highest standards of service and security.</p>
       
<h5 className='fw-bolder mt-4' >Easy Account Maintenance</h5>
       <p>
Managing your fixed deposits with PayZeus is simple and hassle-free. Our user-friendly platform allows you to handle account maintenance with ease, whether it's updating personal information, renewing deposits, or accessing customer support.
</p>
<h4 className='fw-bolder mt-4' >Why Choose PayZeus Fixed Deposits?</h4>
<ul className='open_individual_account_order_list' >
                    
          <li className='p-2 m-0' ><strong>Trustworthy and Secure: </strong>With PayZeus, your money is in safe hands. We employ state-of-the-art security measures to protect your investments.</li>
          <li className='p-2 m-0' ><strong>High Returns: </strong> Enjoy attractive interest rates that help your savings grow.</li>
          <li className='p-2 m-0' ><strong>Safety and Security: </strong>Your investments are safe with PayZeus, thanks to our robust security measures and reliable banking practices.</li>
          <li className='p-2 m-0' ><strong>Convenient Digital Access: </strong> Manage your deposits and other banking needs through our intuitive digital platform.</li>
          <li className='p-2 m-0' ><strong>Comprehensive Banking Services: </strong> Benefit from our all-in-one banking system, offering a range of financial services to meet all your needs.</li>
         </ul>

         <h4 className='fw-bolder mt-4' >Start Investing with PayZeus Today</h4>
         <p>
Secure your financial future with PayZeus fixed deposits. Our competitive rates, flexible options, and comprehensive banking services make it easy to invest with confidence. Join PayZeus today and experience the benefits of a modern, digital banking system designed to help you achieve your financial goals.	
</p>
        </div>
   <Footer />
</div>
  )
}

export default FixedDeposits;
