import React, { useEffect } from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import "./../Styles/aboutus.css";
function AboutUs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id="aboutus">
      <Navbar />

      <div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-12 p-0">

            <h4 className="fw-bolder mt-4">About PayZeus</h4>

            <h5 className="fw-bolder mt-4"> Welcome to PayZeus</h5>
            <p>PayZeus is at the forefront of the digital banking revolution, offering a comprehensive suite of virtual payment systems and innovative financial services. Our mission is to provide seamless, secure, and efficient digital banking solutions to individuals and businesses across the globe. As a leader in the industry, we strive to enhance the financial experience through cutting-edge technology and customer-centric services.</p>
            
            <h4 className="fw-bolder mt-4">Our Services</h4>
            <p>At PayZeus, we offer a full menu of digital banking and payment options designed to meet the diverse needs of our clients. Our services include:</p>
     <h5 className="fw-bolder mt-4"> E-Wallet</h5>
     <p>Our e-wallet service allows you to store, manage, and use your funds effortlessly. With PayZeus e-wallet, you can:</p>
            
            <ul className='open_business_account_order_list' >

              <li className='p-2 m-0' >Send money online to friends and family quickly and securely.</li>
              <li className='p-2 m-0' >Make payments to merchants and retail outlets both online and offline.</li>
              <li className='p-2 m-0' >Pay your bills conveniently with utility and communication service providers.</li>
              

            </ul>


            <h5 className="fw-bolder mt-4">Transfers and Payments</h5>
     <p>Experience the ease of transfers and payments with PayZeus. Our platform supports:</p>
            
            <ul className='open_business_account_order_list' >

              <li className='p-2 m-0' >Instant peer-to-peer transfers.</li>
              <li className='p-2 m-0' >Business payments and cross-border transfers </li>
              <li className='p-2 m-0' >Integration with a wide range of government bodies for tax and fee payments.</li>
              

            </ul>


            <h5 className="fw-bolder mt-4">Savings and Credit Facilities</h5>
     <p>PayZeus offers robust savings and credit facilities to help you manage your finances effectively:</p>
            
            <ul className='open_business_account_order_list' >

              <li className='p-2 m-0' >Time deposits and savings accounts with competitive interest rates.</li>
              <li className='p-2 m-0' >Flexible credit facilities tailored to your needs.</li>
              <li className='p-2 m-0' >Digital debit cards and credit cards for easy access to your funds.</li>
              

            </ul>

            <h5 className="fw-bolder mt-4">Automated Teller Machines (ATMs)</h5>
            <p>Our network of ATMs in select cities and markets ensures that you have access to your money whenever you need it. In the near future, we aim to expand our services to include online shopping facilities, enhancing your digital banking experience.</p>


            <h5 className="fw-bolder mt-4">Security and Convenience</h5>
            <p>At PayZeus, we prioritize the security and convenience of our customers. Our state-of-the-art security measures ensure that your data and transactions are always protected. With PayZeus, you can manage your finances with confidence, knowing that your information is safe.</p>


            <h5 className="fw-bolder mt-4">Our Vision</h5>
            <p>PayZeus aims to revolutionize the digital banking landscape by providing innovative and reliable financial solutions. We believe in empowering our customers with the tools they need to manage their finances efficiently and securely. Our vision is to be the leading provider of virtual payment systems, enabling seamless transactions for individuals and businesses around the world.</p>
           
           
            <h5 className="fw-bolder mt-4">Commitment to Excellence</h5>
            <p>We are committed to delivering excellence in every aspect of our services. From our user-friendly interface to our responsive customer support, we ensure that every interaction with PayZeus is a positive one. Our team of dedicated professionals works tirelessly to enhance our offerings and provide the best possible experience for our customers.</p>
            <h5 className="fw-bolder mt-4">Future Plans</h5>
            <p>Looking ahead, PayZeus plans to expand its range of services to include:</p>
            
            <ul className='open_business_account_order_list' >
              <li className='p-2 m-0' >Cross-border transfers to facilitate international transactions.</li>
              <li className='p-2 m-0' >Comprehensive online shopping facilities to enhance the convenience of online purchases.</li>
             
            </ul>


<p>These future developments are part of our ongoing commitment to meeting the evolving needs of our customers and staying ahead of industry trends.</p>
<h4 className="fw-bolder mt-4">Join PayZeus Today</h4>
<p>Experience the future of digital banking with PayZeus. Join us today and take advantage of our comprehensive range of services designed to meet your financial needs. With PayZeus, managing your finances has never been easier or more secure.</p>

          </div>
        </div>
      </div>


      {/* 
      <div className="container-fluid mb-4 p-0">
        <div className="row">
          <div className="col-lg-12 aboutus_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 aboutus_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">OUR VALUES</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-lg-12 p-0">
            <p className="text-start">
              In God We Trust, the earth is our refuge, the world is our client,
              and the people are our strength.
            </p>
            <p className="text-start">
              No discrimination at any level or on any basis, and we count
              everyone.
            </p>
            <p className="text-start">
              An honest and dedicated service to every customer, around the
              clock, every day.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-4 p-0">
        <div className="row">
          <div className="col-lg-12 aboutus_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 aboutus_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">OUR VISION</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-lg-12 p-0">
            <p className="text-start">
            For the PayZeus digital Banking and Payment System to become the number one choice (i.e., attaining a 70% market share) by the unbanked population in the developing countries and emerging economies worldwide by December 2026. 
            </p>
          </div>
        </div>
      </div>


      <div className="container-fluid mb-4 p-0">
        <div className="row">
          <div className="col-lg-12 aboutus_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 aboutus_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">OUR MISSION</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-lg-12 p-0">
            <p className="text-start">
            To make the online banking, payment and shopping accessible to all those who cannot have a bank account or a credit card with a formal banking and financial institution for any reason.   We aim to promote financial inclusion, improve quality of life, contribute to saving the environment, and enhance economic prosperity and thereby alleviate poverty around the world.
            </p>
          </div>
        </div>
      </div> */}


      {/* <div className="container-fluid mb-4 p-0">
        <div className="row">
          <div className="col-lg-12 aboutus_backgroundimage1">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12 aboutus_backgroundimage2 d-flex align-items-center justify-content-center">
                  <p className="text-white fw-bold display-4">FREQUENTLY ASKED QUESTIONS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-lg-12 p-0">
            <p className="text-start">
            To make the online banking, payment and shopping accessible to all those who cannot have a bank account or a credit card with a formal banking and financial institution for any reason.   We aim to promote financial inclusion, improve quality of life, contribute to saving the environment, and enhance economic prosperity and thereby alleviate poverty around the world.
            </p>
          </div>
        </div>
      </div> */}


      <Footer />
    </div>
  );
}

export default AboutUs;
