import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'

function ToAccessYourAccount() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='ToAccessYourAccount'>
    <Navbar />
   <div className="container py-5">

   <h4 className='fw-bolder mt-4' >To Access Your Account</h4>
   <p>You need to first Register Your Voice on PayZeus Application; PayZeus uses only Voice Recognition to Allow Access!. </p>
<p>At PayZeus, your security is our top priority. That's why we've implemented state-of-the-art voice recognition technology to grant access to your account. To access your PayZeus account, you'll need to first register your unique voice pattern through our secure mobile application.</p>
   <h5 className='fw-bolder mt-4' >Why Voice Recognition?</h5>
   <p>Voice recognition offers a seamless and secure way to access your account. With this innovative feature, you can say goodbye to the hassle of remembering passwords and PINs. Your voice becomes your key to accessing your finances securely and conveniently.</p>

   <h5 className='fw-bolder mt-4' >How It Works</h5>

   <ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Register Your Voice: Using the PayZeus mobile application, you'll be guided through a simple process to register your voice. This involves speaking a few phrases to create a unique voice profile linked to your account.</li>
            <li className='p-2 m-0' >Access Your Account: Once your voice is registered, accessing your PayZeus account is as easy as speaking. Simply launch the app, and the voice recognition system will authenticate your identity, granting you access to your account securely and instantly.</li>
            
            
         </ul>
         <h5 className='fw-bolder mt-4' >Benefits of Voice Recognition</h5>
         <ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Enhanced Security: Voice recognition adds an extra layer of security to your account, reducing the risk of unauthorized access.</li>
            <li className='p-2 m-0' >Convenience: Accessing your account is quick and effortless, saving you time and frustration.</li>
            
            <li className='p-2 m-0' >Personalization: Your voice is as unique as you are, ensuring that only you can access your PayZeus account.</li>
         </ul>

        </div>
   <Footer />
</div>
  )
}

export default ToAccessYourAccount;
