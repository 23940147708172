import React from 'react'
import '../Styles/sector.css'


function Sector() {
  return (
    <div className="sector-head" >
      <div className="sector">
        <div className="container">
          <h3 className="text-center fw-bold">Private Businesses, <span>Public Services & Utilities</span></h3>
          <p className="text-center">
          Private businesses and companies;<br/>
Merchants, including shops, department stores, online shops, street vendors and food stalls; and<br/>
Government departments that provide fee-based public services, including public transport; public utilities, including electricity, gas and water services, and the telephone and mobile-phone companies, who collect payments from their customers,<br/>
with a valid business/trade license and a registered mobile phone number in their business name, can open a PayZeus Business Account and accept payments from other PayZeus account holders via: Point-of-Sale (POS) machines, scanning QR, and their PayZeus Application and Website!     

          </p>
          <div className="sector-main">
            <div className="row">
              <div className="col-md-3 col-sm-3 ">
                <div className="paymentsec">
                  <img className='img-fluid' src="/images/pay-2.jpg" alt="" />
                 
                </div>
              </div>
              <div className="col-md-3 col-sm-3 ">
                <div className="paymentsec">
                <img className='img-fluid' src="/images/pay-1.jpg" alt="" />
                 
                </div>
              </div>
              <div className="col-md-3 col-sm-3 ">
                <div className="paymentsec">
                <img className='img-fluid' src="/images/pay-3.jpg" alt="" />
                 
                </div>
              </div>

              <div className="col-md-3 col-sm-3 ">
                <div className="paymentsec">
                <img className='img-fluid' src="/images/pay-4.jpg" alt="" />
                 
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Sector
