import React from "react";
import {BsDownload} from 'react-icons/bs'
import {AiOutlinePlusCircle} from 'react-icons/ai'
import {BiArrowToRight} from 'react-icons/bi'
import "../Styles/maintain.css";

function Maintain() {
  return (
    <div className="maintain">
      <div className="container">
        <div className="row">
        <div className="col-md-5 d-flex justify-content-center align-items-center">
            <div className="maintain-image">
              <img src="/images/qr.png" alt="" />
            </div>
          </div>

          <div className="col-md-7 d-flex align-items-center">
            <div className="maintain-main">
              <span>Get PayZeus now</span>
              <h2 >
              PayZeus is Free to 
              </h2>
              <h2 className="mb-3" >Download, and to Maintain</h2>
              <p>PayZeus is free to download. And there is no charge to make deposits, make payments and transfers as well as to maintain your individual PauZeus account.  Business accounts are also free to open and maintain, however, there is a very competitive fee applied on revenue receipts by the account holder.</p>

              <div className="maintain_transactions row my-2 d-flex flex-column flex-sm-row justify-content-between mt-4">
                 <div className="col-12 col-sm-6 col-md-6 d-flex align-items-center">
                     <BiArrowToRight />
                     <p className="mx-2 p-0">No Transaction Fee</p>
                 </div>
                 <div className="col-12 col-sm-6 col-md-6  d-flex align-items-center">
                     <BiArrowToRight />
                     <p className="mx-2 p-0">No Minimum Balance to Maintain</p>
                 </div>
              </div>
              
              <div className="maintain_transactions row my-2 d-flex justify-content-between">
                 <div className="col-12 col-sm-6 col-md-6  d-flex align-items-center">
                     <BiArrowToRight />
                     <p className="mx-2 p-0">No Monthly Charges</p>
                 </div>
                 <div className="col-12 col-sm-6 col-md-6  d-flex align-items-center">
                     <BiArrowToRight />
                     <p className="mx-2 p-0">No Limit on Number of Transactions Per Day</p>
                 </div>
              </div>

              <div className="my-4 d-flex ">
              <button className="maintain-learn-btn"> <BsDownload className="mx-1" /> Download App</button>
                <button className="maintain-learn-btn-outline mx-4"> <AiOutlinePlusCircle />  Learn More</button>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default Maintain;
