import React from 'react'
import Home from '../components/Home'
import AboutUs from '../Pages/AboutUs';
import VisitPayzeus from '../Pages/VisitPayzeus';
import OpenIndividualAccount from '../Pages/OpenIndividualAccount';
import OpenBusinessAccount from '../Pages/OpenBusinessAccount';
import RechargePage from '../Pages/RechargePage';
import VisionAndMission from '../Pages/VisionAndMission';
import OurValues from '../Pages/OurValues';
import OurVision from '../Pages/OurVision';
import OurMission from '../Pages/OurMission';
import Faq from '../Pages/Faq';
import Feedback from '../Pages/Feedback';
import News from '../Pages/News';
import DigitalBanking from '../Pages/DigitalBanking';
import BillPaymentsTransfers from '../Pages/BillPaymentsTransfers';
import FixedDeposits from '../Pages/FixedDeposits';
import SavingsAccounts from '../Pages/SavingsAccounts';
import ZeusBankShareholdingAccount from '../Pages/ZeusBankShareholdingAccount';
import DigitalDebitCards from '../Pages/DigitalDebitCards';
import DigitalCreditCards from '../Pages/DigitalCreditCards';
import EmergencyLendingFacilities from '../Pages/EmergencyLendingFacilities';
import UniversityTuitionLoans from '../Pages/UniversityTuitionLoans';
import RewardsonYourPayZeusPurchases from '../Pages/RewardsonYourPayZeusPurchases';
import ReadMore from '../Pages/ReadMore';
import RequiresNoFormalBankAccount from '../Pages/RequiresNoFormalBankAccount';
import NoAgeLimit from '../Pages/NoAgeLimit';
import AllTransactionsHome from '../Pages/AllTransactionsHome';
import RegisteredMobileNumber from '../Pages/RegisteredMobileNumber';
import EfficientEasyUse from '../Pages/EfficientEasyUse';
import EfficientEasyUseSteps from '../Pages/EfficientEasyUseSteps';
import ToAccessYourAccount from '../Pages/ToAccessYourAccount';
import OpenCurrentAccount from '../Pages/OpenCurrentAccount';


import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
  } from "react-router-dom";


  const router = createBrowserRouter([
    {
      path: "/",
      element: ( <Home/>),
    },
    {
      path: "about-us",
      element:  <AboutUs/>,
    },
    {
      path: "/visit",
      element:  <VisitPayzeus/>,
    },
    {
      path: "/open-individual-account",
      element:  <OpenIndividualAccount/>,
    },
    {
      path: "/read-more",
      element:  <ReadMore/>,
    },
    {
      path: "/open-business-account",
      element:  <OpenBusinessAccount/>,
    },
    {
      path: "/digital-banking",
      element:  <DigitalBanking/>,
    },
    {
    path: "/bill-payments-transfers",
      element: <BillPaymentsTransfers/>,
    }, 
    {
      path: "/savings-accounts",
        element: <SavingsAccounts/>,
      }, 
    {
      path: "/fixed-deposits",
        element: <FixedDeposits/>,
      }, 
      {
        path: "/zeusBank-share-holding-account",
          element: <ZeusBankShareholdingAccount/>,
        }, 
        {
          path: "/digital-debit-cards",
            element: <DigitalDebitCards/>,
          }, 
          {
            path: "/emergency-lending-facilities",
            element: <EmergencyLendingFacilities/>,
            }, 
            
            {
              path: "/digital-credit-cards",
              element: <DigitalCreditCards/>,
              }, 
              {
                path: "/university-tuition-loans",
                element: <UniversityTuitionLoans/>,
                }, 
                {
                  path: "/rewards-on-your-payzeus-purchases",
                  element: <RewardsonYourPayZeusPurchases />,
                  }, 
                
            {
      path: "/recharge",
      element:  <RechargePage/>,
    },
    {
      path: "/vision-mission",
      element:  <VisionAndMission/>,
    },

    {
      path: "/our-values",
      element:  <OurValues/>,
    },

    {
      path: "/our-vision",
      element:  <OurVision/>,
    },

    {
      path: "/our-mission",
      element:  <OurMission/>,
    },

    {
      path: "/faq",
      element:  <Faq/>,
    },

    {
      path: "/news",
      element:  <News/>,
    },


    {
      path: "/feedback",
      element:  <Feedback/>,
    },

    {
      path: "/read-more",
      element:  <ReadMore/>,
    },
    {
      path: "/requires-no-formal-bank-account",
      element:  <RequiresNoFormalBankAccount/>,
    },

    {
      path: "/no-age-limit",
      element:  <NoAgeLimit/>,
    },
    
    {
      path: "/all-transactions-Home",
      element:  <AllTransactionsHome/>,
    },
    {
      path: "/registered-mobile-number",
      element:  <RegisteredMobileNumber/>,
    },

    {
      path: "/efficient-easy-use",
      element:  <EfficientEasyUse/>,
    },
    {
      path: "/efficient-easy-use-steps",
      element:  <EfficientEasyUseSteps/>,
    },
    {
      path: "/to-access-your-account",
      element:  <ToAccessYourAccount/>,
    },
    {
      path: "/open-current-account",
      element:  <OpenCurrentAccount/>,
    },
    
  ]);
function Routes() {
  return (
    <div>
       <RouterProvider router={router} />
    </div>
  )
}

export default Routes
