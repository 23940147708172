import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import "./../Styles/aboutus.css";
function OurMission() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  
  return (
    <div id="OurMission">
      <Navbar />








      <div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-12 p-0">

            <h4 className="fw-bolder mt-4 ">Our Mission</h4>
           
            <p className="text-start py-2">To make the online banking, payment and shopping accessible to all those who cannot have a bank account or a credit card with a formal banking and financial institution for any reason.   We aim to promote financial inclusion, improve quality of life, contribute to saving the environment, and enhance economic prosperity and thereby alleviate poverty around the world.</p>
          </div>
        </div>
      </div>


      <Footer />

    </div>
  );
}

export default OurMission;
