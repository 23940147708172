import React from 'react'
import './../Styles/banner.css'
import Typed from "react-typed";
import {
  
  Link,
} from "react-router-dom";

function Banner() {
  return (
    <div className="banner">
        <div className="container">
            <div className="row">
                <div className="col-md-6 d-flex align-items-center">
                   <div className="banner-main">
                   <h2>PayZeus is A Unique Digital </h2>
                   <h2>Banking & Payment</h2>
                   <h2> System</h2>
                   <h4 className="typer-text">
                   A Complete Banking Solution combined with a Real Time Payment (RTP) platform!
                   {/* &nbsp;
                <Typed
                  style={{ color: "#343C5C" }}
                  strings={[
                    "Saving a/c",
                    "FD",
                    "Forex",
                  ]}
                  typeSpeed={80}
                  backSpeed={10}
                  loop
                /> */}
              </h4>
                    <p>No Formal Application, No Bank Account, No Credit Card, No Credit History…</p>
                    <p>Only Your Registered Mobile Number!</p>
                   
                    <div className="d-flex my-4 justify-content-center justify-content-sm-start">
                        <button className='banner-started-btn' >Get Started</button>
                        <button className='mx-4 banner-contact-btn' >
                       
                          Contact Us</button>
                          
                         
                         
                         
                    </div>
                   </div>
                </div>
                <div className="col-md-6">
                    <div className="banner-image">
                        <img className='img-fluid' src="/images/33.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Banner
