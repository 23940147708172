import React from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import "../Styles/deposite.css";

function Deposite() {
  return (
    <div className="deposite">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="deposite-main w-75 w-sm-0 w-md-0">
              <h2 className="mb-3">Open a Current Account</h2>
              <p>
                and a Fixed Deposit Account, as well as Flexible Savings
                Accounts! Own Digital Debit and Credit Cards, and Qualify for
                Education Loans, Emergency Loans, and BNPL (Buy-Now-Pay-Later)
                facilities!
              </p>

              <div className="my-4">
                <button className="deposite-learn-btn">
                  <BiRightArrowCircle />
                  Learn More
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="deposite-image">
              <img src="/images/deposite.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deposite;
