import toast, { Toaster } from 'react-hot-toast';


export const SuccessToaster=(msg)=>{
 return  toast.success(msg, {
    duration: 3000,
    position: 'top-center',
    style:{
      backgroundColor:'green',
      color:'#fff'
    }
})
}

export const ErrorToaster=(msg)=>{
 return  toast.error(msg, {
    duration: 3000,
    position: 'top-center',
    style:{
      backgroundColor:'red',
      color:'#fff'
    }
})
}