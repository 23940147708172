import React, { useState } from "react";
import { AiOutlineBars } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import SideNavBar from "../Modals/SideNavBar";
import { CountryCode } from "../CountryCode";
import { useNavigate } from "react-router-dom";
import "./../Styles/navbar.css";

function Navbar() {
  const navigation =useNavigate()
  const [openSidenavbar, setOpenSidenavbar] = useState(false);

  return (
    <>
      <SideNavBar
        openSidenavbar={openSidenavbar}
        setOpenSidenavbar={setOpenSidenavbar}
      />
      <div className="navbar-main">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-6 col-6 d-flex align-items-center">
              <div onClick={()=>navigation('/')} className="logo">
                <img src="/images/logo.png" alt="" />
              </div>
            </div>
            <div className="col-md-10 col-sm-6 col-6 d-flex justify-content-end align-items-center">
              <div className="navbar-listing d-none d-md-block">
                <ul className="m-0 p-0 d-flex align-items-center">
                  <li onClick={()=>navigation('/')} >Home</li>

                  <div class="navbar_dropdown">
                    <li>Services & Products</li>
                    <div class="navbar_dropdown-content">
                    <a  onClick={()=>navigation("/digital-banking")}>Digital Banking</a>
                    <a  onClick={()=>navigation("/bill-payments-transfers")}>Bill Payments Transfers</a>
                    <a  onClick={()=>navigation("/fixed-deposits")}>Fixed Deposits</a>
                    <a  onClick={()=>navigation("/savings-accounts")}>Savings Accounts</a>
                    <a  onClick={()=>navigation("/zeusBank-share-holding-account")}>ZeusBank Shareholding Account</a>
                    <a  onClick={()=>navigation("/digital-debit-cards")}>Digital Debit Cards</a>
                    <a  onClick={()=>navigation("/digital-credit-cards")}>Digital Credit Cards</a>
                    <a  onClick={()=>navigation("/emergency-lending-facilities")}>Emergency Lending Facilities</a>
                    <a  onClick={()=>navigation("/university-tuition-loans")}>University Tuition Loans</a>
                    <a  onClick={()=>navigation("/rewards-on-your-payZeus-purchases")}>Rewards on Your PayZeus Purchases</a>
                   
                    </div>
                  </div>
                  <div class="navbar_dropdown_Account">
                    <li>Open Account</li>
                    <div class="navbar_dropdown-content_Account">
                      <a onClick={()=>navigation("/open-individual-account")}>Individual</a>
                      <a onClick={()=>navigation("/open-business-account")}>Business</a>
                    </div>
                  </div>
                  
                  <div class="navbar_dropdown">
                    <li>About Us</li>
                    <div class="navbar_dropdown-content dropabtus">
                      <a  onClick={()=>navigation("/about-us")}>About PayZeus</a>
                      <a  onClick={()=>navigation("/our-values")}>Our Values</a>
                      <a onClick={()=>navigation("/our-vision")}>Our Vision</a>
                      <a onClick={()=>navigation("/our-mission")}>Our Mission</a>
                      <a onClick={()=>navigation("/faq")}>FAQs</a>

                      <a onClick={()=>navigation("/news")}>News</a>
                      <a onClick={()=>navigation("/feedback")}>Provide Feedback & Ask</a>
                    
                    </div>
                    </div>

                  <li onClick={()=>navigation('/visit')} className="navbar-visit-payzeus">Visit ZeusBank</li>

                  <div class="navbar_dropdown_recharge">
                    <li onClick={()=>navigation('/recharge')} className="navbar-recharge">Recharge </li>
                    <div class="navbar_dropdown-content_recharge">
                      <a to="/">Using Credit Cards</a>
                         <a to="/">Using Your Formal Bank Account </a>
                          <a to="/">Scanning QR</a>

                      <div class="navbar_dropdown_cash">
                          <a to="/">Using Cash</a>
                        <div class="navbar_dropdown-content_cash">
                          <a to="/">Authorized Stores</a>
                          <a to="/">Authorized Street Vendors </a>
                         </div>
                      </div>
                                          </div>
                  </div>

                  <div class="navbar_dropdown_lang">
                    <li className="d-flex align-items-center">
                      <img
                        width={30}
                        height={30}
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg`}
                        alt="img"
                      />
                      <MdArrowDropDown />
                    </li>
                    <div class="navbar_dropdown-content_lang">
                      {CountryCode?.map((item, i) => {
                        return (
                          <a key={i} className="d-flex align-items-center" to="/">
                            {" "}
                            <img
                              width={20}
                              height={20}
                              className="mx-1"
                              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item?.code}.svg`}
                              alt="img"
                            />
                            {item?.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </ul>
              </div>
              <div className="d-md-none d-block navbar_list_icon">
              <div class="navbar_dropdown_lang">
                    <li className="d-flex align-items-center moblanguage">
                      <img
                        width={30}
                        height={30}
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg`}
                        alt="img"
                      />
                      <MdArrowDropDown />
                    </li>
                    <div class="navbar_dropdown-content_lang ">
                      {CountryCode?.map((item, i) => {
                        return (
                          <a key={i} className="d-flex align-items-center" to="/">
                            {" "}
                            <img
                              width={20}
                              height={20}
                              className="mx-1"
                              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item?.code}.svg`}
                              alt="img"
                            />
                            {item?.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
              </div>

              <div className="d-md-none d-block navbar_list_icon">
                <AiOutlineBars onClick={() => setOpenSidenavbar(true)} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="responsive_nav_bar">
         <div className="container">
           <div className="row">
              <div className="col-6"></div>
              <div className="col-6"></div>
           </div>
         </div>
      </div> */}
    </>
  );
}

export default Navbar;
